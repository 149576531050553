<template>
  <div class="news-template">
    <div class="header"></div>
    <div class="title">
      <h3>金融行业</h3>
    </div>
    <el-divider></el-divider>
    <div class="container">
      <ul
        class="ulstyle met-pager-ajax imagesize"
        data-scale="400x400"
        m-id="16"
      >
        <!-- 极简模式 -->
        <li class="border-bottom1">
          <h4>
            <a
              href="http://www.chinasofthuijin.cn/news2/shownews.php?id=26"
              title="辽宁抚顺清源县农商行资产管理系统"
              target="_self"
              class="font-size-24"
              >辽宁抚顺清源县农商行资产管理系统</a
            >
          </h4>
          <p class="des font-weight-300">
            &nbsp; &nbsp; &nbsp;
            &nbsp;辽宁抚顺清源县农商行固定资产管理系统自上线以来，运行稳定，具备存储资产照片功能，满足了用户的各项需求，受到用户的一致好评！
          </p>
          <p class="info font-weight-300">
            <span>2018-12-26</span>
            <span></span>
            <span
              ><i
                class="icon wb-eye m-r-5 font-weight-300"
                aria-hidden="true"
              ></i
              >69</span
            >
          </p>
        </li>
      </ul>
    </div>
    <div class="newsfooter"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contentText: `&nbsp;&nbsp;本报济南4月17日电  （记者徐锦庚、李蕊）今年是胜利油田发现60周年。位于黄河入海口的胜利油田迄今累计生产原油12.5亿吨，占全国同期陆上原油产量1/5，目前保持年稳产2340万吨以上。`,
    };
  },
};
</script>
<style lang="scss" scoped>
.container {
  text-align: left;
  font-size: 15px;
  margin: 0 10%;
  line-height: 1.7;
  color: #555555;
  .font-size-24 {
    font-size: 24px;
  }
  a {
    color: #555555;
    font-weight: bold;
    text-decoration: none;
  }
}
#met-grid {
  li {
    padding: 2%;
    margin: 1%;
    img {
      width: 300px;
      height: 300px;
    }
  }
}
.news-template {
  text-align: center;
  width: 100%;
  font-family: Hiragino Sans GB, Microsoft Yahei, \\5fae\8f6f\96c5\9ed1, SimSun,
    \\5b8b\4f53, Arial;
  .header {
    height: 6em;
    background: #04c9a0;
  }
  h3 {
    font-size: 1.7em;
    margin: 0.7em 0;
  }
  h5 {
    margin: 0.5em 0;
    color: #909399;
  }
  .content {
    width: 75%;
    margin: 0 12%;
    p {
      font-size: 1.2em;
      text-align: left;
      line-height: 2.2;
      margin-bottom: 2em;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
}
</style>